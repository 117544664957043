import React from 'react'
import Duo from './Duo';

const data = {
    questions: [
        "Please tell us about a time you most successfully hacked some (non-computer) system to your advantage.",
        "Please tell us in one or two sentences about the most impressive thing other than this startup that you have built or achieved.",
        "Tell us about things you've built before. For example apps you’ve built, websites, open source contributions. Include URLs if possible.",
        "List any competitions/awards you have won, or papers you’ve published.",
        "Who writes code, or does other technical work on your product? Was any of it done by a non-founder? Please explain.",
        "Are you looking for a cofounder?",

    
    
    ],
    answers: [`
    In 2018, I automated my own job as a writer within a large financial services firm. 
    To hack the job code wasn't enough; I needed to shift how OTHER PEOPLE were doing their job.

    First, I wanted to automate input collection without getting asked about it. I created an Excel tool - some UI and a macro - to share with my team. It helped them a little, so now everyone was using it. (I added logging so could see it was being used.)

    Second, I switched the order of doing things. Before, it was too much work to write each report twice. But now I could generate an "early draft" and quickly send it to the analysts. It moved pressure from me to them: I was no longer waiting on their input to START the work, but to FINISH it.

    Third, analysts would send me info in emails, which I would need to copy-paste into an input spreadsheet. This was tedious, so I sold them on how effective it would be to just fill this one handy spreadsheet...

    Nobody noticed.
  `,
  `
  On top of running my startup, I’ve made time to learn front-end development. I put in the hours – early mornings, late nights – and went from zero to being able to build just about anything.
  `,
  ` 
I wanted Excel, but for text: a system to ask ANY question on a piece of text, apply that question to an entire corpus, and then apply a combined measure (for example: average, top-5, histogram). Then do the same for other corpora – and compare. (Disclosure: this was a large project, and I roped in my husband for an extra pair of hands.) 
With that in place, you could create all sorts of solutions: 

1. The first use case was Social Listening – here’s a client case study: https://bit.ly/4dV8yRD  
2. The second use case was Competitive Benchmarking (Like Apple Health, for expert writing). This now involved adding an entire scraping infrastructure to the system. 
Landing page: https://www.fintext.io/services/benchmark/  
Case study – Aviva Investors: https://bit.ly/40hDNDj  

And just for fun...Deflect annoying questions like a professional investor: https://glitter.fintext.io/
`,
`
1. Financial storytelling using time-series classification: https://bit.ly/3NDReG5  
2. A new way to sentiment-tag financial news: https://bit.ly/4hkd7Io  
3. How Finance Uses NLP - 8 case studies in banking and investment management (web+ PDF): https://bit.ly/3Ul9GXU  
`,
`
I do. Python, ML, JavaScript, front-end, back-end, the lot.

On architecture decisions I consult with my husband: we met at 8200 and he's been a software developer for 25 years.
`,
`
No`

]
  };

const Founder = () => {
  return (
//     <div>
//     {data.questions.map((question, index) => (
//         <div key={index} style={{ marginBottom: "20px" }}>
//           <h3><strong>{question}</strong></h3>
//           <p style={{ whiteSpace: "pre-line" }}>{data.answers[index]}</p>
//         </div>
//       ))}
//   </div>
    <div className='narrow_container'>
    <Duo data = {data} title = {"Founder"}/>
    </div>
  )
}

export default Founder