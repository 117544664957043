import React from 'react'

const Duo = ({data, title}) => {
  return (
    <div>
    <div className='font-special text-3xl'>{title}</div>
    {data.questions.map((question, index) => (
        <div key={index} style={{ marginBottom: "20px" }}>
          <h3><strong>{question}</strong></h3>
          <p style={{ whiteSpace: "pre-line" }}>{data.answers[index]}</p>
        </div>
      ))}
  </div>
  )
}

export default Duo