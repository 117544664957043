import './AppTW.css';
// import './TipTap.scss'
import React, { Fragment, useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Navbar from './components/layout/Navbar';
import Container from './components/layout/Container';

import HomePage from './components/Pages/Home/HomePage';
import EditorPage from './components/Pages/EditorPage/EditorPage';
import Login2 from './components/auth/Login2';
import Register  from './components/auth/Register';
import Catch from './components/layout/Catch';
import PrivateRoute from './components/routing/PrivateRoute';
import { LOGOUT } from './actions/types';

import Fetch from './components/Tools/Fetch';
// import Gist from './components/Tools/Gist';
import Clipper from './components/Tools/Clipper/Clipper';
import Collections from './components/Pages/Collections/Collections';
import CollectionView from './components/Pages/CollectionView/CollectionView';
import Gister from './components/Tools/Gister';

import Full from './components/Pages/ApplS25/Full';

//Redux
import { Provider } from 'react-redux';
import store from './store';
import setAuthToken from './utils/setAuthToken';
import { loadUser } from './actions/auth';




const App = () => {

  useEffect(() => {
    // check for token in Local Storage when app first runs
    if (localStorage.token) {
      // if there is a token set axios headers for all requests
      setAuthToken(localStorage.token);
    }
    // try to fetch a user, if no token or invalid token we
    // will get a 401 response from our API
    store.dispatch(loadUser());

    // log user out from all tabs if they log out in one tab
    window.addEventListener('storage', () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });
  }, []);

  return (
    <Provider store = {store}>
    <Router>
      <Fragment >
       <Navbar />
        <Routes>
          <Route path = "/" element = {<HomePage />}></Route>
          <Route path = "/login" element = {<Login2 />}></Route>

          

          <Route
                path="/editor"
                element={<PrivateRoute component={EditorPage} />}
              />
          <Route
                path="/gist"
                element={<PrivateRoute component={Gister} />}
              />
          <Route
                path="/clipper"
                element={<PrivateRoute component={Clipper} />}
              />

          {/* <Route path = "/test2" element = {<PrivateRoute component={Gist}/>} /> */}
          <Route element = {<Container />}>

              <Route path = "/applS25" element = {<Full />}></Route>
              
              <Route
                path="/fetch"
                element={<PrivateRoute component={Fetch} />}
              />


              {/* <Route
                path="/register"
                element={<PrivateRoute component={Register} />}
              /> */}
              <Route
                path="/collections"
                element={<PrivateRoute component={Collections} />}
              />
              <Route
                path="/collectionview/*"
                element={<PrivateRoute component={CollectionView} />}
              />
              <Route path="*" element={<Catch />} />
          </Route>
        </Routes>
      </Fragment>
    </Router>
    </Provider>
  );
}

export default App;


