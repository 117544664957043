import React, {Fragment} from 'react'
import Company from './Company'
import Founder from './Founder'

const Full = () => {
  return (
    <Fragment>
        <Founder />
        <Company />
    </Fragment>
  )
}

export default Full