// import { createDispatchHook } from 'react-redux';
import api from '../utils/api';
// import { setAlert } from './alert';
import {
    GET_COLLECTION,
    GET_COLLECTIONS,
    ADD_COLLECTION,
    UPDATE_COLLECTION,
    REMOVE_COLLECTION,
    REMOVE_SAVED_COLLECTION,
    CLEAR_COLLECTION,
    COLLECTION_ERROR,
    TRANSFORM_SUCCESS,
    TRANSFORM_FAIL,
    TRANSFORM_UPDATE_SUCCESS,
    TRANSFORM_UPDATE_FAIL,
    CLEAR_TRANSFORM,
    CLEAR_ALL_COLLECTIONS
  
  } from './types';


export const createAltParags = (extractId, title, snippets) => async dispatch => {

    //Generate more paragraphs out of the snippets
    let altParags = ""; //one string for now.
    try {
      const response = await api.post(`/edit/altransform`, {title,snippets});
      altParags = response.data.parags
      // console.log('received altParags!')
      dispatch({
        type: TRANSFORM_SUCCESS,
        payload:altParags
      });

      if(altParags){
        try {
          // console.log(`Calling PUT /extract/alts/${extractId} with altParags:`, altParags);
          const extractResponse = await api.put(`/extract/alts/${extractId}`, {altParags});
          dispatch ({
            type: TRANSFORM_UPDATE_SUCCESS,
            payload:extractResponse.data
          });
  
        } catch (err) {
        dispatch({
          type: TRANSFORM_UPDATE_FAIL,
          payload: { msg: err.response.statusText, status: err.response.status }
        });
        }
      }

    }catch (err) {
      dispatch({
        type: TRANSFORM_FAIL,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }

  }

export const clearTransform = () => dispatch => {
    dispatch({ type: CLEAR_TRANSFORM}); 
};

// get all the user's collections
export const getCollections = () => async dispatch => {

    // Getting the user data is taken care by the auth middleware. 
    // It'll be in req.user the endpoint gets. 
    // The endpoint is the one that grabs all the USER's bookmarks.
    try {

        // ping the api for all user's bookmarks
        const res = await api.get('/collection');
    
        dispatch({
          type: GET_COLLECTIONS,
          payload: res.data
        });
      } catch (err) {
        dispatch({
          type: COLLECTION_ERROR,
          payload: { msg: err.response.statusText, status: err.response.status }
        });
      }
    };

// Get k recent collections
export const getRecentCollections = (count) => async (dispatch) => {
  try {
    const res = await api.get(`/collection/recent/${count}`);

    dispatch({
      type: GET_COLLECTIONS,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: COLLECTION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Get a single collection
export const getCollection = (id) => async (dispatch) => {
    try {
      const res = await api.get(`/collection/${id}`);
  
      dispatch({
        type: GET_COLLECTION,
        payload: res.data
      });
    } catch (err) {
      dispatch({
        type: COLLECTION_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
  };

// Get the bookmarks contained in a dish belonging to a collection
export const getCollectionBookmarks = (dishId) => async dispatch => {
  
  //1. Get the dish belonging to the collection.
 
  try {
    const res = await api.get(`/dish/${dishId}`);

    

    //2. Grab its list of Bookmark Ids
    const bookmarkIds = res.data.bookmarks;
  

    try {
      
      //3. Call the DB to get all the bookmarks in the list.
      const res = await api.post('/bookmarks/getBookmarksByIds', bookmarkIds);

      //4. Get all the extractIds from the boomarks and call the database for these
      const bookmarkArray = Array.from(res.data)
      const extractIdArray = bookmarkArray.map(bm => bm.extractPointer);

      try {
        
        const extractsRes = await api.post('/extract/getExtractsByIds', extractIdArray);
        const extractsArray = Array.from(extractsRes.data)
        const idToExtractMapping = {};
        for (const extract of extractsArray) {
            idToExtractMapping[extract._id] = extract; 
          }
        
      //5. Munge them into an object that also contains the urls
        const enrichedBookmarks = res.data.map((key) => {
          return {
          ...key,
          url: idToExtractMapping[key.extractPointer].url
        };
        });

               //5. dispatch GET_COLLECTION
        dispatch({
          type: GET_COLLECTION,
          payload: enrichedBookmarks
        });

      } catch (err) {
        dispatch({
          type: COLLECTION_ERROR,
          payload: { err }
        });
      }
      
    } catch (err) {
      dispatch({
        type: COLLECTION_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    };

    
  } catch (err) {
    dispatch({
      type: COLLECTION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  };
};

// Get the bookmarks + extracts contained in a dish belonging to a collection
export const loadFullCollection = (dishId) => async dispatch => {
  //1. Get the dish belonging to the collection.
 
  try {
    const res = await api.get(`/dish/${dishId}`);

    

    //2. Grab its list of Bookmark Ids
    const bookmarkIds = res.data.bookmarks;
  

    try {
      
      //3. Call the DB to get all the bookmarks in the list.
      const res = await api.post('/bookmarks/getBookmarksByIds', bookmarkIds);

      //4. Get all the extractIds from the boomarks and call the database for these
      const bookmarkArray = Array.from(res.data)
      const extractIdArray = bookmarkArray.map(bm => bm.extractPointer);

      try {
        
        const extractsRes = await api.post('/extract/getExtractsByIds', extractIdArray);
        const extractsArray = Array.from(extractsRes.data)
        const idToExtractMapping = {};
        for (const extract of extractsArray) {
            idToExtractMapping[extract._id] = extract; 
          }
        
      //5. Munge them into an object that also contains the extracts
        const enrichedBookmarks = res.data.map((key) => {
          return {
          ...key,
          extract: idToExtractMapping[key.extractPointer]
        };
        });
        
        
               //5. dispatch GET_COLLECTION
        dispatch({
          type: GET_COLLECTION,
          payload: enrichedBookmarks
        });

      } catch (err) {
        dispatch({
          type: COLLECTION_ERROR,
          payload: { err }
        });
      }
      
    } catch (err) {
      dispatch({
        type: COLLECTION_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    };

    
  } catch (err) {
    dispatch({
      type: COLLECTION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  };
};


// Add a collection
export const saveCollection = (collectionData) => async dispatch => {
  
  const title = collectionData.urlTitle;
  const snippets = collectionData.snippets;

  let paragraph = "";
  let extractId = "";

  try {
    const response = await api.post(`/edit/transform`, {title,snippets});

    paragraph = response.data.paragraph
    dispatch({
      type: TRANSFORM_SUCCESS,
      payload:paragraph
    });
  } catch (error) {
    dispatch({
      type: TRANSFORM_FAIL
    });
  }

  const updatedCollectionData = {
    ...collectionData,
    genParag: paragraph
  };

  try {
        
        // take the collectionData and hit the endpoint.
        const res = await api.post('/collection', updatedCollectionData);
    
        //On success first pull extractId, then dispatch success 
        extractId = res.data.bookmark.extractPointer;

        // Dispatch the createAltParags action
        // The createAltParags function is an asynchronous thunk action creator that returns a function. 
        // To execute this function within updateCollection, you need to dispatch it.
        dispatch(createAltParags(extractId, title, snippets));

        // with payload of the newly created collection 
        dispatch({
            type: ADD_COLLECTION,
            payload: res.data.collection
          });




        // dispatch(setAlert('Bookmark Created', 'success'));
      } catch (err) {
        //On failure dispatch error    
        dispatch({
          type: COLLECTION_ERROR,
          payload: { msg: err.response.statusText, status: err.response.status }
        });
      }



    };
    
    
// Update a collection
export const updateCollection = (collectionData) => async(dispatch) => {
  
  const title = collectionData.title;
  const snippets = collectionData.snippets;

  let paragraph = "";
  let extractId = "";

  try {
    const response = await api.post(`/edit/transform`, {title,snippets});

    paragraph = response.data.paragraph
    dispatch({
      type: TRANSFORM_SUCCESS,
      payload:paragraph
    });
  } catch (error) {
    dispatch({
      type: TRANSFORM_FAIL
    });
  }


  const collectionId = collectionData.id
  const updatedCollectionData = {
    ...collectionData,
    genParag: paragraph
  };

  try {

    const res = await api.put(`/collection/bookmark/${collectionId}`, updatedCollectionData);
  
    //res.data contains  collection and bookmark

    //On success grab extractId dispatch success 
    extractId = res.data.bookmark.extractPointer;
    
    if(extractId) { //we only have extract id if the bookmark creation went smoothly
      // Dispatch the createAltParags action. By removing await we will dispatch UPDATE_COLLECTION first for the save
      // and second through createAltParags for the updating.
      // The createAltParags function is an asynchronous thunk action creator that returns a function. 
      // To execute this function within updateCollection, you need to dispatch it.
      dispatch(createAltParags(extractId, title, snippets));

    }

    dispatch({
      type: UPDATE_COLLECTION,
      payload: res.data.collection
    });




    
  } catch (err) {
    //On failure dispatch error    
    dispatch({
      type: COLLECTION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }



};


// Remove collection
export const removeCollection = (id) => async (dispatch) => {
    try {
      await api.delete(`/collection/${id}`);
  
      dispatch({
        type: REMOVE_COLLECTION,
        payload: id
      });
  
    //   dispatch(setAlert('Post Removed', 'success'));
    } catch (err) {
      dispatch({
        type: COLLECTION_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
  };

  // Clear loaded collection
export const clearLoadedCollection = () => async (dispatch) => {
    try {
      dispatch({
        type: CLEAR_COLLECTION
      });

    } catch (err) {
      dispatch({
        type: COLLECTION_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
  }

// Clear ALL loaded collections
export const clearAllLoadedCollections = () => async (dispatch) => {
  try {
    dispatch({
      type: CLEAR_ALL_COLLECTIONS
    });

  } catch (err) {
    dispatch({
      type: COLLECTION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
}

    // Clear saved collection
export const clearSavedCollection = () => async (dispatch) => {
  try {
    dispatch({
      type: REMOVE_SAVED_COLLECTION
    });

  } catch (err) {
    dispatch({
      type: COLLECTION_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
}

  //remove bookmark from a collection
  export const removeBookmark = (collectionId, bookmarkId) => async (dispatch) => {
    try {
      const res = await api.delete(`collection/bookmark/${collectionId}/${bookmarkId}`);

      dispatch({
        type: UPDATE_COLLECTION,
        payload: res.data
      });

    } catch (err) {
      dispatch({
        type: COLLECTION_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
    }
  };