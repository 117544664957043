import React, {Fragment} from 'react'
import Duo from './Duo';

const company = {
    questions: [
        "Company name",
        "Describe what your company does in 50 characters or less.",
        "Company URL, if any",
        "Demo Video",
        "Please provide a link to the product, if any.",
        "Login details",
        "What is your company going to make? Please describe your product and what it does or will do.",
        "Where do you live now, and where would the company be based after YC?",
        "Explain your decision regarding location.",
    ],
    answers: [
      `
      Stedili
      `,
      `
      Expert writing made easy
      `,
      `
      https://www.stedili.com/
      `,
      `
      Not yet uploaded
      `,
      `
      https://www.stedili.com/
      `,
      `
      To come
      `,
      `
   Newsletters, blogs and articles help B2B companies sell to high-value clients, but to be effective these can’t be junk. 
Stedili is a productivity tool that helps companies in knowledge-intensive industries create regular expert writing easily. 
It is the first solution to capture the workflows of professional writers, allowing companies to reconcile the conflict between quality factual output and the demand to keep manufacturing it. 
The tool helps users build and connect ideas, reducing the writing effort to mostly clicks, drags and drops.

Longer term, we see this reshaping how professionals write, by rethinking what a 'document' file is: not just the words on a page but also the backdrop context used to write the text.
  `,
  `
  London, UK
  `,
  `
  No better place for a B2B startup this side of the Atlantic.
`

]
  };

const progress = {
    questions: [
        "How far along are you?",
        "How long have each of you been working on this? How much of that has been full-time? Please explain.",
        "What tech stack are you using, or planning to use, to build this product?",
        "Are people using your product?",
        "How many active users or customers do you have? How many are paying? Who is paying you the most, and how much do they pay you?",
        "Do you have revenue?",
        "If you are applying with the same idea as a previous batch, did anything change? If you applied with a different idea, why did you pivot and what did you learn from the last idea?",
        `If you have already participated or committed to participate in an incubator, "accelerator" or "pre-accelerator" program, please tell us about it.`,
    ],
    answers: [`
   - Built an MVP. (Sept '23-Dec '23) 
- Demoed to about ~30 users (Jan '24-March '24). Onboarded 18. From this I learned two main things: 

One, I gave users a 'task sheet' to do post-demo. 17 of the users logged in and completed the tasks, but only 5 users then logged in at least a second time. So users were happy to follow a set process, but struggled figuring out a flow to get value from the app. Two, I used Stedili to launch a newsletter called 'Fund Marketer' on Substack. I tracked both engagement and how long it took me to create posts. Iterated on software improvements. (Dec '23-Jun '24) 

- Got first paying users. (May '24) 
- Got into a JP Morgan sponsored pitching competition to develop a better pitch to clients (Jun '24). Made it to the final and ended up getting 1-1 mentoring from both JP Morgan and Newable. 
- Started writing a step-by-step handbook for creating high-value written marketing. ('Sep 24) The plan is to sell it coupled with a month of free usage of Stedili, then upsell the continued use of Stedili. 
  `,
  `
  Left my job to start FinText in 2020, full time since. Working on Stedili for a year, 10 months full time, the rest on FinText consulting. 
  `,
  `
  The app is built with Node, React, Redux, MongoDB. Some text-processing endpoints are hitting a separate Python app, built with Flask. 
`,
`
Yes
`,
`
Two early customers: UBS Asset Management paid me £6000/year. A small company called Finominal Finance asked for a demo, loved it and bought it outright. Is paying £899/year in quarterly installments.
`,
`
No
`,
`
NA
`,
`
I am in two different mentorship programmes: 
One started in June 2024, is funded by Innovate UK and delivered by Newable. My mentor sold a business in asset management recruitment, and has hands-on-experience selling to professional services companies. 
The other is the JP Morgan female founders mentorship programme, which started in September 2024 and runs for 9 months. My mentor is a high-profile investment banker whose remit covers European tech. 

`

]
  };

const idea = {
    questions: [
        "Why did you pick this idea to work on? Do you have domain expertise in this area? How do you know people need what you're making?",
        "Who are your competitors? What do you understand about your business that they don't?",
        "How do or will you make money? How much could you make?",
        "Which category best applies to your company?",
    ],
    answers: [`
        When I left my job to start FinText, I built an analytics product for marketing teams in professional services firms. It sold, but the analytics showed that lots of investment companies didn’t have the resources to publish much. Also, they were paying me – and other freelancers – £500-£1000/article, because few can write to an audience of professional investors. 
        
        As a business owner, I knew having an engaged audience made money. I hated depending on LinkedIn, and would have loved a newsletter. I had a solid mailing list, but not the time to write regularly. 
        
        Through my business school, I got a group of MBA students to trawl the YC directory, analyzing the websites of 445 B2B companies funded in 2020-2023, in high-knowledge industries. 76% of these companies didn’t offer a newsletter sign up, and 40% didn’t have a blog.
  `,
  `
  Writing tech falls into two buckets, based on how they view text. 
1. ‘Prompt and pray’ tools. (Jasper, Writer, ChatGPT): Text is like beads on a string, the writer has no real control. If the output is bad, all they can do is try again. Like a casino! 
2. Productivity tools (Microsoft (Word), Google (Docs), Notion). Text is like Lego: small chunks get pulled together and connected. But the tool doesn’t care how long it takes users to ‘put it together’. 

Stedili is firmly in the Lego camp, but with a flow towards the end result. We know the expert writing process converges to doing the same few things repeatedly: 
- Source information. Possibly keep a link for reference. 
- Extract and collect the relevant bits. 
- Pull threads and connect ideas. 
- Combine, move around, edit. 

To do each of the above, writers take four kinds of actions. From hardest to easiest they are: Discovery, search, selection, binary choice.

Stedili is both built for the above process AND makes actions easier by knocking down a notch the difficulty of an action on the scale. 

  `,
  `
The plan is to create a step-by-step manual coupled with one month of free usage - to get clients to a quick win, and then upsell continued use.

For comps, I consider Notion, which is said to be profitable, and is currently valued at $5bn. Cost per seat, when including the AI features is $20/month. 
But also Axios HQ, which is purely B2B, started in 2021, and now have ~500 enterprise clients and nearly $7m in ARR.

`,
`
B2B SaaS
`
]
  };

const equity = {
    questions: [
        "Have you formed ANY legal entity yet?",
        "Please list all legal entities you have and in what state or country each was formed (e.g. Delaware C Corp, Mexican SAPI, Singapore Pvt Ltd, etc.).",
        "Please describe the breakdown of the equity ownership in percentages among the founders, employees and any other stockholders. If there are multiple founders, be sure to give the equity ownership of each founder and founder title (e.g. CEO).",
        "Have you taken any investment yet?",
        "Are you currently fundraising",
    ],
    answers: [`
   Yes
  `,
  `
  FinText Ltd. UK Limited company. Registration number: 12657047
  `,
  `
  CEO 100%
`,
`
No
`,
`
No
`
]
  };

  const curious = {
    questions: [
        "What convinced you to apply to Y Combinator? Did someone encourage you to apply? Have you been to any YC events?",
        "How did you hear about Y Combinator?",
    ],
    answers: [`
   Initially, I was determined to bootstrap FinText by selling consulting work. Indeed, I banked over £50K for the business this way, while building software, and then got revenue off the analytics-based stuff. BUT: 
I wasn't taking a salary, consulting slows you down, and clients get confused: are you a consultant or a software business? 

Having used Stedili, I know there is no way I am ever going back to a classic editor. I also see how people respond to the drag-and-drop writing. I think this could be big. 
I am applying for early decision for a future batch, the Summer '25 batch.
  `,
  `
  After automating my job, I searched for how others had handled the situation. I found a discussion on HN on a related article [1]. Started reading HN, and got a fair dose of exposure to YC. 
  
  [1] https://news.ycombinator.com/item?id=18120322
  `,

]
  };
const Company = () => {
  return (
    <div className='narrow_container'>
        <Duo data = {company} title = {"Company"} />
        <Duo data = {progress} title = {"Progress"}/>
        <Duo data = {idea} title = {"Idea"}/>
        <Duo data = {equity} title = {"Equity"}/>
        <Duo data = {curious} title = {"Curious"}/>
    </div>
  )
}

export default Company